<template>
    <BT-Blade
        v-if="currentLocationID != null"
        :canClose="false"
        :canRefresh="false"
        hideBackButton>
        <template v-slot:toolbar-right>
            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="journeys"
                :onFilter="filterJourneys"
                :params="{ includeDetails: false, departureLocationID: currentLocationID }"
                returnObject
                :searchProps="['journeyName']"
                :showToggle="showJourneysToggle"
                title="Journeys"
                width="550"
                @change="addSlipJourney">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <span v-if="item.startedOn != null">Started</span>
                            <span v-else-if="!item.isReady">*Not Final Yet</span>
                            <span v-else>Ready</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text>{{ item.startedOn || item.dueStartOn | toDayShortDate }}</v-list-item-action-text>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="stock-consignments"
                :onFilter="filterConsignments"
                :params="{ filterBy: 'Unresolved', includeDetails: false, locationID: currentLocationID, properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' }"
                returnObject
                :searchProps="['buyer.companyName', 'consignmentNumber']"
                :showToggle="showConsignmentsToggle"
                title="Stock Consignments"
                width="550"
                @change="item => { addSlipConsignment(item, 'consignment') }">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueDate | toDayShortDate }}</v-list-item-action-text>
                        <v-list-item-action-text>CO# {{ item.consignmentNumber }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="stock-consignments"
                :onFilter="filterConsignments"
                :params="{ filterBy: 'Unresolved', includeDetails: false, filterBy: 'Transfers', properties: 'ID,Buyer,DestinationLocation,DueDate,ConsignmentNumber' }"
                returnObject
                :searchProps="['buyer.companyName', 'consignmentNumber']"
                :showToggle="showTransfersToggle"
                title="Transfers"
                width="550"
                @change="item => { addSlipConsignment(item, 'transfer') }">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueDate | toDayShortDate }}</v-list-item-action-text>
                        <v-list-item-action-text>CO# {{ item.consignmentNumber }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="customer-consignments"
                :onFilter="list => { return list.filter(z => !loadedConsignmentIDs.some(y => y == z.id)) }"
                :params="{ includeDetails: false, filterBy: 'Customer Is Courier,Ready', departureLocationID: currentLocationID }"
                returnObject
                :searchProps="[]"
                :showToggle="showPickupsToggle"
                title="Pickups"
                width="550"
                @change="addSlipPickup">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ item.dueDate | toShortDate }}</v-list-item-action-text>
                        <v-list-item-action-text>CO# {{ item.consignmentNumber }}</v-list-item-action-text>
                    </v-list-item-action>
                </template>
            </BT-Select-Dialog>
            
            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="releases"
                :onFilter="list => { return list.filter(z => !loadedReleaseIDs.some(y => y == z.id)) }"
                :params="{ includeDetails: false, filterBy: 'Pending,Unbundled', departureLocationID: currentLocationID, properties: 'ID,DestinationLocation,DueArrivalOn,DueDepartureOn' }"
                returnObject
                :searchProps="['destinationLocation.suburb','destinationLocation.companyAccount.companyName','destinationLocation.streetName','bundleName']"
                :showToggle="showReleasesToggle"
                title="Releases"
                width="550"
                @change="addSlipRelease">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.destinationLocation | toCompanyNameAndLocationLine }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.dueDepartureOn | toDayShortDate }} <v-spacer /> {{ item.bundleName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </BT-Select-Dialog>

            <BT-Select-Dialog
                canSearch
                hideButton
                navigation="releases"
                :onFilter="list => { return list.filter(z => !loadedReleaseIDs.some(y => y == z.id)) }"
                :params="{ includeDetails: false, filterBy: 'Pending,Bundled', departureLocationID: currentLocationID, properties: 'ID,DestinationLocation,DueArrivalOn,DueDepartureOn,BundleName' }"
                returnObject
                :searchProps="['destinationLocation.suburb','destinationLocation.companyAccount.companyName','destinationLocation.streetName','bundleName']"
                :showToggle="showBundlesToggle"
                title="Bundled Releases"
                width="550"
                @change="addSlipRelease">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.destinationLocation | toCompanyNameAndLocationLine }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.dueDepartureOn | toDayShortDate }} <v-spacer /> {{ item.bundleName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </BT-Select-Dialog>
            
            <BT-Button-Confirm
                v-if="isMobile && currentPickingSlip != null && currentPickingSlip.filteredConsignments.some(y => y.isChanged)"
                @click="savePickingSlip(currentPickingSlip)"
                class="primary mx-1"
                icon="mdi-content-save"
                key="3"
                text="Save" />
            
            <BT-Menu
                v-if="!useFastProcessing"
                buttonClass="primary mx-1"
                icon="mdi-plus"
                isButton
                :text="isMobile ? null : 'Open'"
                title="Open Picking Slip">
                <template v-slot>
                    <v-list-item @click="showConsignmentsToggle = !showConsignmentsToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Consignment</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showJourneysToggle = !showJourneysToggle">
                        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Journey</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showPickupsToggle = !showPickupsToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard-arrow-up</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Pickup</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showReleasesToggle = !showReleasesToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard-arrow-right</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Release</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showBundlesToggle = !showBundlesToggle">
                        <v-list-item-icon><v-icon>mdi-clipboard-play-multiple</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Bundled Release</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showTransfersToggle = !showTransfersToggle">
                        <v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Open Transfer</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </BT-Menu>

            <BT-Select-List-Dialog
                v-if="!isMobile"
                @ok="(list) => { measurements = list }"
                buttonClass="primary mx-1"
                icon="mdi-calculator-variant"
                isButton
                itemText="measurementName"
                itemValue="id"
                label="Measurements"
                multiple
                navigation="measurements"
                :small="false"
                text="Quantity Breakdowns"
                width="350" />

            <BT-Button-Confirm
                v-if="!isMobile"
                buttonClass="primary mx-1"
                icon="mdi-database-refresh"
                isButton
                msg="Refresh stock?"
                :small="false"
                text="Refresh Stock"
                title="Refresh Stock"
                @click="refreshStock" />
                
        </template>

        <template v-slot:toolbar>
            <v-app-bar-nav-icon @click="slipDrawer = !slipDrawer">
                <v-icon>mdi-clipboard-multiple</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title>Picking Slips</v-toolbar-title>
            <v-spacer />
        </template>

        <template>
            <v-navigation-drawer
                absolute
                clipped
                hide-overlay
                style="height: 100%;"
                width="300"
                v-model="slipDrawer">
                <v-toolbar dense class="primary" dark>
                    <v-btn icon @click="slipDrawer = false">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ currentPickingSlip != null && currentPickingSlip.isPacking ? 'Consignments' : 'Picking Slips' }}</v-toolbar-title>
                    <v-spacer />
                   
                    <BT-Select-List-Dialog
                        @ok="(list) => { measurements = list }"
                        buttonClass="primary"
                        icon="mdi-calculator-variant"
                        itemText="measurementName"
                        itemValue="id"
                        label="Measurements"
                        multiple
                        navigation="measurements"
                        :small="false"
                        width="350" />

                    <BT-Button-Confirm
                        buttonClass="primary"
                        icon="mdi-database-refresh"
                        msg="Refresh stock?"
                        :small="false"
                        title="Refresh Stock"
                        @click="refreshStock" />
                </v-toolbar>

                <v-list-item-group v-if="currentPickingSlip != null && currentPickingSlip.isPacking">
                    <v-list-item v-for="(cons, index) in currentPickingSlip.filteredConsignments" :key="index" @click="selectConsignment(cons.id, currentPickingSlip)">
                        <v-list-item-icon class="mx-0 px-0">
                            <v-slide-x-transition>
                                <v-icon v-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == cons.id).every(y => y.totalAssigned == y.quantity && y.totalAssigned == y.totalPicked)" class="success--text">mdi-check-all</v-icon>
                                <v-icon v-else-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == cons.id).every(y => y.totalAssigned == y.quantity)" class="success--text">mdi-check</v-icon>
                                <v-icon v-else-if="allData.allConsignmentOrderItems.filter(y => y.consignmentID == cons.id).some(y => y.totalAssigned > 0)" class="warning--text">mdi-check</v-icon>
                                <v-icon v-else class="error--text">mdi-close</v-icon>
                            </v-slide-x-transition>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ cons.buyer.companyName }}</v-list-item-title>
                            <v-list-item-subtitle>#{{ cons.consignmentNumber }} - {{ cons.destinationLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-else>
                    <v-list-item v-for="(pSlip, index) in pickingSlips" :key="index" @click="selectPanelV(index)">
                        <v-list-item-icon class="mx-0 px-0">
                            <v-icon v-if="pSlip.filteredConsignmentOrderItems.every(x => x.totalAssigned >= x.quantity && x.totalAssigned == x.totalPicked)" class="success--text" small>mdi-check-all</v-icon>
                            <v-icon v-else-if="pSlip.filteredConsignmentOrderItems.every(x => x.totalAssigned >= x.quantity)" class="success--text" small>mdi-check</v-icon>
                            <v-icon v-else-if="pSlip.filteredConsignmentOrderItems.every(x => x.totalAssigned > 0)" class="warning--text" small>mdi-check</v-icon>
                            <v-icon v-else class="error--text" small>mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ pSlip.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-navigation-drawer>
            <BT-Blades
                :maximizedCount="4"
                :anchorBlades="[{ bladeName: 'all-picking-slips', isMinimized: false }]">
                <template v-slot="bladesData">
                    <BT-Blade
                        :bladesData="bladesData"
                        bladeName="all-picking-slips"
                        :canClose="false"
                        colClass="ma-0 pa-0"
                        isChildBlade
                        flexColumn
                        :resizable="false">
                        <v-tabs-items v-model="panelV">
                            <v-tab-item
                                v-for="(pickingSlip, index) in pickingSlips"
                                :key="index">
                                <Picking-Slip-Blade
                                    @close="closePickingSlip"
                                    @refresh="refreshPickingSlip"
                                    @save="savePickingSlip"
                                    @saveMirror="saveMirror"
                                    @selectOrderItem="selectOrderItem"
                                    @selectConsignment="selectConsignment"
                                    :allData="allData"
                                    :bladesData="bladesData"
                                    :measurements="measurements"
                                    :pickingSlip="pickingSlip" />
                            </v-tab-item>
                        </v-tabs-items>
                    </BT-Blade>
                </template>
            </BT-Blades>
            <v-navigation-drawer
                app
                bottom
                clipped
                hide-overlay
                right
                style="height: 100%;"
                v-model="stockDrawer"
                width="350">
                <BT-Blade-List
                    :canClose="false"
                    :canRefresh="false"
                    :canSelect="false"
                    flexColumn
                    hideBackButton
                    :items="allData.filteredStockEntries"
                    :title="allData.currentOrderItem != null ? allData.currentOrderItem.product.abbreviation : 'Stock'">
                    <template v-slot:toolbar>
                        <v-btn icon @click="stockDrawer = false" large>
                            <v-icon v-if="!isMobile" large>mdi-arrow-right</v-icon>
                            <v-icon v-else large>mdi-arrow-down</v-icon>
                        </v-btn>
                        <v-toolbar-title key="3">{{ allData.currentOrderItem != null ? allData.currentOrderItem.product.abbreviation : 'Stock' }}</v-toolbar-title>
                        <v-spacer />
                        <span v-if="allData.currentOrderItem != null" class="text-right">
                            <BT-Increment-Quantity
                                :measurements="measurements"
                                prefix="QTY: "
                                :productID="allData.currentOrderItem.productID"
                                spanClass="text-right"
                                v-model.number="allData.currentOrderItem.quantity">
                            </BT-Increment-Quantity>
                        </span>
                    </template>
                    <template v-slot:top>
                        <v-btn v-if="isLengthyArray(allData.filteredStockEntries)" class="primary" height="50" width="50%" @click="assignAll">Assign All</v-btn>
                        <v-btn v-if="isLengthyArray(allData.filteredStockEntries)" class="primary" height="50" width="50%" @click="pickAll">Pick All</v-btn>
                    </template>
                    <template v-slot="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.batch ? item.batch.batchcode : 'No Batch' }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-row dense>
                                    <v-col cols="4">
                                        <BT-Increment-Quantity
                                            v-model.number="item.available"
                                            :hideZero="false"
                                            :measurements="measurements"
                                            prefix="Free: "
                                            :productID="item.productID"
                                            :spanClass="item.available < 0 ? 'error--text' : ''" />
                                    </v-col>
                                    <v-col cols="4">
                                        <BT-Increment-Quantity-Edit
                                            v-model.number="item.quantity"
                                            @input="changeQuantity(item)"
                                            :measurements="measurements"
                                            prefix="Assign: "
                                            :productID="item.productID" />
                                    </v-col>
                                    <v-col cols="4">
                                        <BT-Increment-Quantity-Edit
                                            v-model.number="item.quantityPicked"
                                            @input="changePicked(item)"
                                            :measurements="measurements"
                                            prefix="Pick: "
                                            :productID="item.productID"
                                            :spanClass="item.quantityPicked == item.quantity ? (item.quantityPicked == 0 ? '' : 'success--text') : 'error--text'" />
                                    </v-col>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:bottom>
                        <div v-if="isLengthyArray(allData.filteredStockEntries)">
                            <v-btn block class="primary my-2" height="50" v-for="m in measurements" :key="m.id" @click="assignAll(m)">
                                Assign Up To {{ m.measurementName }}
                            </v-btn>
                        </div>
                    </template>
                </BT-Blade-List>
            </v-navigation-drawer>
            <BT-Snack v-model="msg" />
            <v-overlay :value="loadingMsg != null" class="text-center">
                <v-progress-circular indeterminate size="64" />
                <div>{{ loadingMsg }}</div>
            </v-overlay>
        </template>
    </BT-Blade>
</template>
    
<script>
    import { firstBy } from 'thenby';
    
    export default {
        name: 'Picking-Slips-Blade-New',
        components: {
            BTIncrementQuantity: () => import('~components/BT-Increment-Quantity.vue'),
            BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
            BTMenu: () => import('~components/BT-Menu.vue'),
            BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
            BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue'),
            PickingSlipBlade: () => import('~home/picking-slips/Picking-Slip-Blade.vue'),
        },
        data: function() {
            return {
                allData: { 
                    allConsignmentEntryItems: [],
                    allConsignmentOrderItems: [],
                    allStockConsignments: [],
                    allStockEntries: [],
                    consignmentID: null,
                    currentOrderItem: null,
                    filteredStockEntries: [],
                    warningMsg: null
                },
    
                currentLocationID: null,
                currentOrderItem: null,
                isSourceChanged: false,
                
                loadedConsignmentIDs: [],
                loadedJourneyIDs: [],
                loadedReleaseIDs: [],
    
                loadingMsg: null,
                measurements: [],
                msg: null,
                panelV: null,
                pickingSlips: [],
    
                showBundlesToggle: false,
                showJourneysToggle: false,
                showConsignmentsToggle: false,
                showPickupsToggle: false,
                showReleasesToggle: false,
                showTransfersToggle: false,

                stockIncrements: null,
    
                slipDrawer: false,
                stockDrawer: false,

                useFastProcessing: false,
            }
        },
        watch: {
            panelV: function(val) {
                this.selectPickingSlip(val);
            },
        },
        computed: {
            currentPickingSlip() {
                return this.pickingSlips[this.panelV];
            },
            isMobile() {
                return this.$vuetify.breakpoint.mobile;
            }
        },
        async mounted() {
            //see if a picking slip needs opening from the start
            this.loadingMsg = 'Preparing';
            var journey, consignment, release = null;
            try {
                this.useFastProcessing = this.$route.params.mode == 'instant' || this.$route.query.mode == 'instant';

                if (this.$route.params.journeyID != null || this.$route.query.journeyID != null) {
                    journey = await this.$BlitzIt.store.get('journeys', this.$route.params.journeyID || this.$route.query.journeyID, { includeDetails: false });
                    this.currentLocationID = journey.departureLocationID;
                }
                else if (this.$route.params.consignmentID != null || this.$route.query.consignmentID != null) {
                    consignment = await this.$BlitzIt.store.get('stock-consignments', this.$route.params.consignmentID || this.$route.query.consignmentID, { includeDetails: false });
                    this.currentLocationID = consignment.departureLocationID;
                }
                else if (this.$route.params.releaseID != null || this.$route.query.releaseID != null) {
                    release = await this.$BlitzIt.store.get('releases', this.$route.params.releaseID || this.$route.query.releaseID, { includeDetails: false });
                    this.currentLocationID = release.departureLocationID;
                }
            }
            catch {
                console.log('p slip pre-mount failed')
            }

            if (this.currentLocationID == null) {
                this.currentLocationID = this.selectedLocationID();
    
                if (this.currentLocationID == null) {
                    this.currentLocationID = await this.$selectItem({
                        navigation: 'locations', 
                        itemText: 'locationName',
                        itemValue: 'id',
                        required: true });
                }
            }
    
            try {
                this.loadingMsg = 'Seeing what is in stock';
    
                var stockItems = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.currentLocationID });
                this.allData.allStockEntries = stockItems.map(x => {
                    return Object.assign({}, { ...x, originalAvailable: x.available, quantityPicked: 0, quantity: 0 })
                });

                if (journey != null) {
                    await this.addSlipJourney(journey);
                }
                else if (consignment != null) {
                    await this.addSlipConsignment(consignment, consignment.isTransfer ? 'transfer' : 'consignment');
                }
                else if (release != null) {
                    await this.addSlipRelease(release);
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        methods: {
            selectPanelV(ind) {
                this.panelV = ind;
                //this.slipDrawer = false;
            },
            async getMirror(id) {
                var mirror = null;
                
                try {
                    mirror = await this.$BlitzIt.store.get('picking-slips', id);
                }
                catch {
                    mirror = null;
                }
                
                // if (mirror == null) {
                //     mirror = {
                //         id: id,
                //         pickingSlipItems: [],
                //         rowVersion: null
                //     }
                // }

                return mirror;
            },
            async saveMirror(pSlip) {
                //update mirror
                try {
                    this.loadingMsg = 'Taking a snapshot of this picking slip';

                    if (pSlip.mirror == null) {
                        pSlip.mirror = {
                            id: pSlip.id,
                            createdOn: this.$BlitzIt.auth.createUTC(),
                            pickingSlipItems: [],
                            rowVersion: null
                        };
                    }

                    var mirror = this.copyDeep(pSlip.mirror);

                    mirror.pickingSlipItems = [];

                    //update pickingSlipItems
                    mirror.pickingSlipItems = pSlip.filteredConsignmentOrderItems.map(z => { 
                        var psItem = pSlip.mirror.pickingSlipItems.find(x => x.productID == z.productID);
                        return {
                            id: psItem != null ? psItem.id : null,
                            productID: z.productID,
                            quantityNeeded: z.quantity,
                            quantityAssigned: z.totalAssigned,
                            quantityPicked: z.totalPicked
                        }
                    });

                    var res = null;

                    if (mirror.rowVersion == null) {
                        //post
                        res = await this.$BlitzIt.store.post('picking-slips', mirror);
                    }
                    else {
                        //patch
                        res = await this.$BlitzIt.store.patch('picking-slips', mirror);
                    }

                    if (res != null) {
                        pSlip.mirror = res;
                    }

                    this.selectPickingSlip(this.panelV);
                    // await this.refreshPickingSlip(pSlip);
                }
                catch (err) {
                    console.log(this.extractErrorDescription(err));
                }
                finally {
                    this.loadingMsg = null;
                }
            },
            async addSlipConsignment(item, t = 'consignment') {
                if (item != null) {
                    this.loadedConsignmentIDs.push(item.id);
                    var title = this.$BlitzIt.auth.formTZ(item.dueDate, 'ccc dd/LL');
                    if (t == 'transfer') {
                        title = `${title} - Transfer #${item.consignmentNumber}`;
                    }
                    else {
                        title = `${title} - #${item.consignmentNumber}: ${item.buyer.companyName}`;
                    }

                    var mirror = await this.getMirror(item.id);

                    this.pickingSlips.push({
                        id: item.id,
                        slipType: 'consignment',
                        isLoaded: false,
                        isPacking: false,
                        title: title,
                        canBulk: false,
                        consignmentIDs: [item.id],
                        consignmentID: item.id,
                        filteredConsignments: [],
                        filteredConsignmentOrderItems: [],
                        isBulk: true,
                        isTransfer: t == 'transfer' ? true : false,
                        refreshToggle: false,
                        mirror: mirror
                    });

                    this.selectPanelV(this.pickingSlips.length - 1);
    
                    await this.pullConsignments();
                }
            },
            async addSlipJourney(item) {
                if (item != null) {
    
                    this.loadedJourneyIDs.push(item.id);
                    var consignmentIDsRes = await this.$BlitzIt.api.get('journeys', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
                    this.loadedConsignmentIDs.push(...consignmentIDsRes.data.data);
                    this.loadedConsignmentIDs = [new Set(...this.loadedConsignmentIDs)];
                    
                    var mirror = await this.getMirror(item.id);
                    
                    this.pickingSlips.push({
                        id: item.id,
                        slipType: 'journey',
                        isLoaded: false,
                        isPacking: false,
                        title: this.$BlitzIt.auth.formTZ(item.startedOn || item.dueStartOn, 'ccc dd/LL') + ' - ' + item.journeyName,
                        canBulk: consignmentIDsRes.data.data.length > 1,
                        consignmentIDs: consignmentIDsRes.data.data,
                        consignmentID: consignmentIDsRes.data.data.length == 1 ? consignmentIDsRes.data.data[0] : null,
                        filteredConsignments: [],
                        filteredConsignmentOrderItems: [],
                        isBulk: true,
                        isTransfer: false,
                        refreshToggle: false,
                        mirror: mirror
                    });
    
                    this.selectPanelV(this.pickingSlips.length - 1);
    
                    await this.pullConsignments();
                }
            },
            async addSlipPickup(item) {
                if (item != null) {
                    this.loadedConsignmentIDs.push(item.id);
                    
                    var mirror = await this.getMirror(item.id);
                    
                    this.pickingSlips.push({
                        id: item.id,
                        slipType: 'consignment',
                        isLoaded: false,
                        isPacking: false,
                        title: '#' + item.consignmentNumber + ': ' + item.buyer.companyName,
                        canBulk: false,
                        consignmentIDs: [item.id],
                        consignmentID: item.id,
                        filteredConsignments: [],
                        filteredConsignmentOrderItems: [],
                        isBulk: true,
                        isTransfer: false,
                        refreshToggle: false,
                        mirror: mirror
                    });
    
                    this.selectPanelV(this.pickingSlips.length - 1);
    
                    await this.pullConsignments();
                }
            },
            async addSlipRelease(item) {
                if (item != null) {
                    this.loadedReleaseIDs.push(item.id);
                    var consignmentIDsRes = await this.$BlitzIt.api.get('releases', '/GetConsignmentIDs/GetConsignmentIDs/' + item.id, null, null);
                    if (consignmentIDsRes.length == 0) {
                        console.log('no ids found');
                    }
                    this.loadedConsignmentIDs.push(...consignmentIDsRes.data.data);
                    this.loadedConsignmentIDs = [new Set(...this.loadedConsignmentIDs)];
                    
                    var mirror = await this.getMirror(item.id);
                    
                    this.pickingSlips.push({
                        id: item.id,
                        slipType: 'release',
                        isLoaded: false,
                        isPacking: false,
                        title: item.destinationLocation.companyAccount.companyName, //'Release',
                        canBulk: consignmentIDsRes.data.data.length > 1,
                        consignmentIDs: consignmentIDsRes.data.data,
                        consignmentID: consignmentIDsRes.data.data.length == 1 ? consignmentIDsRes.data.data[0] : null,
                        filteredConsignments: [],
                        filteredConsignmentOrderItems: [],
                        isBulk: consignmentIDsRes.data.data.length > 1,
                        isTransfer: false,
                        refreshToggle: false,
                        mirror: mirror
                    });
                    
                    this.selectPanelV(this.pickingSlips.length - 1);
    
                    await this.pullConsignments();
                }
            },
            addConsignment(consignment) {
                this.allData.allStockConsignments.push(Object.assign({}, consignment, { isChanged: false, isSourceChanged: false }));
    
                consignment.consignmentOrderItems.forEach(oItem => {
                    this.allData.allConsignmentOrderItems.push(Object.assign({}, oItem, { totalAssigned: 0, totalPicked: 0, consignmentID: consignment.id } ));
                })
    
                consignment.consignmentEntryItems.forEach(cItem => {
                    this.allData.allConsignmentEntryItems.push(Object.assign({}, cItem, { difference: 0, consignmentID: consignment.id, isChanged: false } ));
    
                    var stockEntry = this.allData.allStockEntries.find(x => x.productID == cItem.productID && x.batchID == cItem.batchID);
                    if (stockEntry == null) {
                        this.allData.allStockEntries.push({
                            id: null,
                            productID: cItem.product.id,
                            product: cItem.product,
                            batchID: cItem.batchID,
                            batch: cItem.batch,
                            onHold: 0,
                            available: 0,
                            wasted: 0,
                            quantity: 0,
                            quantityPicked: 0,
                            originalAvailable: 0
                        });
                    }
                })
    
                this.allData.allConsignmentOrderItems.filter(y => y.consignmentID == consignment.id)
                    .forEach(oItem => {
                        var cItems = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID == consignment.id && y.productID == oItem.productID);
                        oItem.totalAssigned = cItems.sum(z => z.quantity);
                        oItem.totalPicked = cItems.sum(z => z.quantityPicked);
                    })
            },
            async assignAll(measurementUpTo = null) {
                if (this.allData.currentOrderItem == null) {
                    return;
                }
    
                var currentOrderItem = this.allData.currentOrderItem;
                if (this.currentPickingSlip.consignmentID == null) {
                    //bulk
                    this.currentPickingSlip.filteredConsignments.forEach(x => {
                        this.assignAllForConsignment(x.id, currentOrderItem.productID);
                    })
                }
                else {
                    this.assignAllForConsignment(this.currentPickingSlip.consignmentID, currentOrderItem.productID);
                }

                if (measurementUpTo != null) {
                    if (this.stockIncrements == null) {
                        this.stockIncrements = await this.$BlitzIt.store.getAll('stock-increments');
                    }
                    
                    var increment = this.stockIncrements.find(y => y.productID == currentOrderItem.productID && y.measurementID == measurementUpTo.id);
                    
                    if (increment != null && increment.units > 0) {
                        var excess = currentOrderItem.totalAssigned % increment.units;

                        if (excess > 0) {
                            var toAdd = increment.units - excess;

                            this.allData.filteredStockEntries.forEach(sEntry => {
                                if (toAdd > 0 && sEntry.available > 0) {
                                    var batchToAdd = sEntry.available > toAdd ? toAdd : sEntry.available;
                                    sEntry.quantity += batchToAdd;
                                    this.changeQuantity(sEntry);
                                    toAdd -= batchToAdd;
                                }
                            })
                        }
                    }
                }
            },
            assignAllForConsignment(consignmentID, productID) { //update stock entries and order items and entry items
                if (this.allData != null && this.isLengthyArray(this.allData.allStockEntries)) {
                    var orderItem = this.allData.allConsignmentOrderItems.find(x => x.productID == productID && x.consignmentID == consignmentID);
                    var isChanged = false;
    
                    if (orderItem == null) {
                        return; 
                    }
    
                    var bulkOrderItem = this.currentPickingSlip.filteredConsignmentOrderItems.find(y => y.productID == productID);
                    if (bulkOrderItem == null) {
                        return; //should never happen
                    }
    
                    var stockEntries = this.allData.filteredStockEntries; //.filter(x => x.productID == productID);
                    var cItems = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID == consignmentID && y.productID == productID);
                    var supplied = cItems.sum(x => x.quantity);
                    
                    var remainingNeeds = orderItem.quantity - supplied;
                    if (remainingNeeds > 0) {
                        stockEntries.forEach(stockEntry => {
                            if (remainingNeeds > 0 && stockEntry.available > 0) {
                                //find where to put it
                                isChanged = true;
                                var cItem = cItems.find(x => x.batchID == stockEntry.batchID);
                                if (cItem == null) {
                                    //so create a consignment entry item for this consignment
                                    cItem = {
                                        id: null,
                                        isChanged: true,
                                        consignmentID: orderItem.consignmentID,
                                        productID: productID,
                                        product: orderItem.product,
                                        quantity: 0,
                                        quantityPicked: 0,
                                        batch: stockEntry.batch,
                                        batchID: stockEntry.batchID,
                                        difference: 0,
                                    }
    
                                    this.allData.allConsignmentEntryItems.push(cItem);
                                }
    
                                if (remainingNeeds >= stockEntry.available) {
                                    stockEntry.quantity += stockEntry.available;
    
                                    //bulkEntryItem.quantity += stockEntry.available;
                                    //bulkEntryItem.difference += stockEntry.available;
    
                                    cItem.quantity += stockEntry.available;
                                    cItem.difference += stockEntry.available;
    
                                    orderItem.totalAssigned += stockEntry.available;
                                    bulkOrderItem.totalAssigned += stockEntry.available;
    
                                    remainingNeeds -= stockEntry.available;
                                    
                                    stockEntry.available = 0;
                                }
                                else {
                                    stockEntry.quantity += remainingNeeds;
                                    stockEntry.available -= remainingNeeds;
    
                                    // bulkEntryItem.quantity += remainingNeeds;
                                    // bulkEntryItem.difference += remainingNeeds;
                                    
                                    cItem.quantity += remainingNeeds;
                                    cItem.difference += remainingNeeds;
    
                                    orderItem.totalAssigned += remainingNeeds;
                                    bulkOrderItem.totalAssigned += remainingNeeds;
    
                                    remainingNeeds = 0;
                                }
                            }
    
                            stockEntry.originalQuantity = stockEntry.quantity;
                        })
                    }
    
                    if (isChanged) {
                        this.markAsChanged(consignmentID);
                    }
                }
            },
            changePicked(stockEntryItem) {
                if (this.allData.currentOrderItem == null) {
                    return;
                }
                
                if (stockEntryItem.quantityPicked > stockEntryItem.quantity) {
                    this.msg = "Cannot pick more than has been assigned";
                    stockEntryItem.quantityPicked = stockEntryItem.originalPicked;
                    return;
                }
    
                if (stockEntryItem.quantityPicked < 0) {
                    stockEntryItem.quantityPicked = stockEntryItem.originalPicked;
                    this.msg = "Cannot pick less than 0";
                }
    
                var dif = stockEntryItem.quantityPicked - stockEntryItem.originalPicked;
                var consignmentID = this.currentPickingSlip.consignmentID;
                var actualDif = dif;
                
                if (dif != 0) {
                    if (consignmentID == null) {
                        if (dif > 0) {
                            //add
                            this.allData.allConsignmentEntryItems
                                .filter(x => x.productID == stockEntryItem.productID && this.currentPickingSlip.consignmentIDs.some(y => y == x.consignmentID))
                                .forEach(x => {
                                    var toPick = x.quantity - x.quantityPicked;
                                    var effectiveDif = 0;
                                    if (toPick > 0) {
                                        if (toPick >= actualDif) {
                                            effectiveDif = actualDif;
                                            x.quantityPicked += actualDif;
                                            actualDif = 0;
                                        }
                                        else {
                                            effectiveDif = toPick;
                                            x.quantityPicked += toPick;
                                            actualDif -= toPick;
                                        }
    
                                        var orderItem = this.allData.allConsignmentOrderItems.find(y => y.consignmentID == x.consignmentID && y.productID == x.productID);
                                        if (orderItem == null) {
                                            orderItem = {
                                                id: null,
                                                consignmentID: x.consignmentID,
                                                product: x.product,
                                                productID: x.productID,
                                                quantity: 0,
                                                totalAssigned: 0,
                                                totalPicked: 0
                                            }
                                            this.allData.allConsignmentOrderItems.push(orderItem);
                                        }
                                        orderItem.totalPicked += effectiveDif;
    
                                        this.markAsChanged(x.consignmentID);
                                    }
                                })
                        }
                        else {
                            //remove
                            this.allData.allConsignmentEntryItems
                                .filter(x => x.productID == stockEntryItem.productID && this.currentPickingSlip.consignmentIDs.some(y => y == x.consignmentID))
                                .forEach(x => {
                                    var effectiveDif = 0;
                                    if (actualDif < 0 && x.quantityPicked > 0) {
                                        if (x.quantityPicked + actualDif >= 0) {
                                            effectiveDif = actualDif;
                                            x.quantityPicked += actualDif;
                                            actualDif = 0;
                                        }
                                        else {
                                            effectiveDif = 0 - x.quantityPicked
                                            actualDif += x.quantityPicked;
                                            x.quantityPicked = 0;
                                        }
                                        
                                        var orderItem = this.allData.allConsignmentOrderItems.find(y => y.consignmentID == x.consignmentID && y.productID == x.productID);
                                        if (orderItem == null) {
                                            orderItem = {
                                                id: null,
                                                consignmentID: x.consignmentID,
                                                product: x.product,
                                                productID: x.productID,
                                                quantity: 0,
                                                totalAssigned: 0,
                                                totalPicked: 0
                                            }
                                            this.allData.allConsignmentOrderItems.push(orderItem);
                                        }
                                        
                                        orderItem.totalPicked += effectiveDif;
    
                                        this.markAsChanged(x.consignmentID);
                                    }
                                })
                        }
    
                        actualDif = dif - actualDif;
                    }
                    else {
                        //consignment
                        var eItem = this.allData.allConsignmentEntryItems.find(x => x.consignmentID == consignmentID && x.productID == stockEntryItem.productID && x.batchID == stockEntryItem.batchID);
                        if (eItem != null) {
                            eItem.quantityPicked += actualDif;
                        }
                        else {
                            //add
                            this.allData.allConsignmentEntryItems.push({
                                id: null,
                                isChanged: true,
                                consignmentID: consignmentID,
                                productID: stockEntryItem.productID,
                                product: stockEntryItem.product,
                                batchID: stockEntryItem.batchID,
                                batch: stockEntryItem.batch,
                                quantity: 0,
                                quantityPicked: actualDif,
                                difference: 0
                            })
                        }
    
                        var orderItem = this.allData.allConsignmentOrderItems.find(y => y.consignmentID == consignmentID && y.productID == stockEntryItem.productID);
                        if (orderItem == null) {
                            orderItem = {
                                id: null,
                                consignmentID: consignmentID,
                                product: stockEntryItem.product,
                                productID: stockEntryItem.productID,
                                quantity: 0,
                                totalAssigned: 0,
                                totalPicked: 0
                            }
                            this.allData.allConsignmentOrderItems.push(orderItem);
                        }
                        
                        orderItem.totalPicked += actualDif;
                        this.markAsChanged(orderItem.consignmentID);
                    }
    
                    this.allData.currentOrderItem.totalPicked += actualDif;
    
                    // //adjust bulk order item
                    // var bulkOrderItem = this.allData.allConsignmentOrderItems.find(y => y.productID == stockEntryItem.productID && y.consignmentID == stockEntryItem.consignmentID)
                    // bulkOrderItem.totalPicked += actualDif;
    
                    stockEntryItem.quantityPicked += (dif - actualDif);
                }
    
                stockEntryItem.originalPicked = stockEntryItem.quantityPicked;
            },
            changeQuantity(stockEntryItem) {
                if (this.allData.currentOrderItem == null) {
                    return;
                }
    
                var dif = stockEntryItem.quantity - stockEntryItem.originalQuantity;
    
                if (dif != 0) {
                    var consignmentID = this.currentPickingSlip.consignmentID;
                    var actualDif = dif;
    
                    if (consignmentID == null) {
                        //bulk, so spread and update entryItem accordingly
                        actualDif = this.spreadAround(dif, stockEntryItem);
                    }
                    else {
                        //consignment
                        var eItem = this.allData.allConsignmentEntryItems.find(x => x.consignmentID == consignmentID && x.productID == stockEntryItem.productID && x.batchID == stockEntryItem.batchID);
                        if (eItem != null) {
                            eItem.quantity += dif;
                            eItem.difference += dif;
                        }
                        else {
                            //add
                            this.allData.allConsignmentEntryItems.push({
                                id: null,
                                isChanged: true,
                                productID: stockEntryItem.productID,
                                product: stockEntryItem.product,
                                batchID: stockEntryItem.batchID,
                                batch: stockEntryItem.batch,
                                quantity: dif,
                                quantityPicked: 0,
                                difference: dif,
                                consignmentID: consignmentID
                            })
                        }
                        
                        var oItem = this.allData.allConsignmentOrderItems.find(y => y.consignmentID == consignmentID && y.productID == stockEntryItem.productID);
                        if (oItem == null) {
                            oItem = {
                                id: null,
                                product: stockEntryItem.product,
                                productID: stockEntryItem.productID,
                                quantity: 0,
                                totalAssigned: 0,
                                totalPicked: 0,
                                consignmentID: consignmentID
                            }
                            this.allData.allConsignmentOrderItems.push(oItem);
                        }
                        oItem.totalAssigned += dif;
                        this.markAsChanged(consignmentID);
                    }

                    var bulkOrderItem = this.currentPickingSlip.filteredConsignmentOrderItems.find(x => x.productID == stockEntryItem.productID);

                    bulkOrderItem.totalAssigned += actualDif;
                    
                    stockEntryItem.quantity += (dif - actualDif);
                    stockEntryItem.available -= actualDif;
                    stockEntryItem.originalQuantity = stockEntryItem.quantity;

                }
            },
            closePickingSlip(pickingSlip) {
                if (pickingSlip != null) {
                    var ind = this.pickingSlips.findIndex(y => y.slipType == pickingSlip.slipType && y.id == pickingSlip.id);
                    if (ind >= 0) {
                        this.pickingSlips.splice(ind, 1);
    
                        pickingSlip.consignmentIDs.forEach(consignmentID => {
                            var originalConsignment = this.allData.allStockConsignments.find(y => y.id == consignmentID);
    
                            if (!this.pickingSlips.some(slip => slip.consignmentIDs.some(id => id == consignmentID))) {
                                //not grouped, so remove completely
                                this.allData.allConsignmentEntryItems
                                    .filter(x => x.consignmentID == consignmentID)
                                    .forEach(cItem => {
                                        //restore what was assigned and not saved
                                        var original = originalConsignment.consignmentEntryItems.find(y => y.productID == cItem.productID && y.batchID == cItem.batchID);
                                        var dif = original != null ? cItem.quantity - original.quantity : 0;
                                        if (dif != 0) {
                                            var stockEntry = this.allData.allStockEntries.find(y => y.productID == cItem.productID && y.batchID == cItem.batchID);
                                            if (stockEntry != null) {
                                                stockEntry.available += dif;
                                            }
                                        }
                                    })
                                
                                this.allData.allConsignmentEntryItems = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID != consignmentID);
                                this.allData.allConsignmentOrderItems = this.allData.allConsignmentOrderItems.filter(y => y.consignmentID != consignmentID);
    
                            }
                        })
                    }
    
                    this.selectPickingSlip(this.panelV);
                }
            },
            filterConsignments(list) {
                // var rList = list.filter(x => !this.loadedConsignmentIDs.some(j => j == x.id));
                var rList = list.filter(x => !this.allData.allStockConsignments.some(j => j.id == x.id));
                rList.sort(firstBy(x => x.dueDate).thenBy(x => x.buyer.companyName));
                return rList;
            },
            filterJourneys(list) {
                var rList = list.filter(x => !this.loadedJourneyIDs.some(j => j == x.id));
                rList.sort(firstBy(x => x.startedOn || x.dueStartOn).thenBy(x => x.journeyName));
                return rList;
            },
            filterReleases(list) {
                var rList = list.filter(x => !this.loadedReleaseIDs.some(j => j == x.id));
                rList.sort(firstBy(x => x.dueDepartureOn).thenBy(x => x.destinationLocation.suburb));
                return rList;
            },
            markAsChanged(consignmentID) {
                var con = this.allData.allStockConsignments.find(y => y.id == consignmentID);
                if (con != null) {
                    con.isChanged = true;
                }
            },
            pickAll() {
                if (this.allData.currentOrderItem == null) {
                    return;
                }
    
                var currentOrderItem = this.allData.currentOrderItem;
                if (this.currentPickingSlip.consignmentID == null) {
                    //bulk
                    this.currentPickingSlip.filteredConsignments.forEach(x => {
                        this.pickAllForConsignment(x.id, currentOrderItem.productID);
                    })
                }
                else {
                    this.pickAllForConsignment(this.currentPickingSlip.consignmentID, currentOrderItem.productID);
                }
            },
            pickAllForConsignment(consignmentID, productID) {
                if (consignmentID == null || productID == null) {
                    return;
                }
    
                var isChanged = false;
    
                this.allData.allConsignmentEntryItems
                    .filter(x => x.consignmentID == consignmentID && x.productID == productID && x.quantityPicked != x.quantity)
                    .forEach(cItem => {
                        var pickDif = cItem.quantity - cItem.quantityPicked;
                        if (pickDif > 0) {
                            //changes will be made
                            cItem.quantityPicked += pickDif;
    
                            var filteredStockEntry = this.allData.filteredStockEntries.find(y => y.productID == cItem.productID && y.batchID == cItem.batchID);
                            if (filteredStockEntry != null) {
                                filteredStockEntry.quantityPicked += pickDif;
                                filteredStockEntry.originalPicked = filteredStockEntry.quantityPicked;
                            }
    
                            var bulkOrderItem = this.currentPickingSlip.filteredConsignmentOrderItems.find(y => y.productID == productID);
                            bulkOrderItem.totalPicked += pickDif;
    
                            var orderItem = this.allData.allConsignmentOrderItems.find(y => y.consignmentID == consignmentID && y.productID == productID);
                            if (orderItem == null) {
                                orderItem = {
                                    id: null,
                                    consignmentID: consignmentID,
                                    product: cItem.product,
                                    productID: cItem.productID,
                                    quantity: 0,
                                    totalAssigned: 0,
                                    totalPicked: 0
                                }
                                this.allData.allConsignmentOrderItems.push(orderItem);
                            }
    
                            orderItem.totalPicked += pickDif;
                            isChanged = true;
                        }
                    })
    
                if (isChanged) {
                    this.markAsChanged(consignmentID);
                }
            },
            async pullConsignments() {
                if (this.isLengthyArray(this.pickingSlips)) {
                    var loadedIDs = this.allData.allStockConsignments.map(x => x.id);
                    var neededIDs = this.pickingSlips.flatMap(x => x.consignmentIDs);
                    var excessIDs = neededIDs.filter(x => !loadedIDs.some(y => y == x));
    
                    if (this.isLengthyArray(excessIDs)) {
                        try {
                            //pull in sets of 20
                            this.loadingMsg = 'Pulling Consignments';
                            
                            do {
                                var ids = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                                var results = this.copyDeep(await this.$BlitzIt.store.getAll('stock-consignments', { ids: ids.toString(), includeDetails: true }));
                                
                                results.forEach(consignment => {
                                    this.addConsignment(consignment);
                                })
    
                                excessIDs = excessIDs.filter(x => !ids.some(y => y == x));
                            } while (this.isLengthyArray(excessIDs))
                        }
                        catch (err) {
                            this.msg = this.extractErrorDescription(err);
                        }
                        finally {
                            this.loadingMsg = null;
                        }
                    }
    
                    this.selectPickingSlip(this.panelV);
                }
            },
            async refreshPickingSlip(pickingSlip) {
                if (this.isLengthyArray(this.pickingSlips)) {
                    //if journey, or release?
                    if (pickingSlip.slipType == 'journey') {
                        var consignmentIDsRes = await this.$BlitzIt.api.get('journeys', '/GetConsignmentIDs/GetConsignmentIDs/' + pickingSlip.id, null, null);
                        pickingSlip.consignmentIDs = consignmentIDsRes.data.data;
                    }
                    else if (pickingSlip.slipType == 'release') {
                        var consignmentIDsRess = await this.$BlitzIt.api.get('releases', '/GetConsignmentIDs/GetConsignmentIDs/' + pickingSlip.id, null, null);
                        pickingSlip.consignmentIDs = consignmentIDsRess.data.data;
                    }
                    else if (pickingSlip.slipType == 'consignment') {
                        pickingSlip.consignmentIDs = [pickingSlip.id];
                    }
    
                    if (this.isLengthyArray(pickingSlip.consignmentIDs)) {
                        try {
                            //pull in sets of 20
                            this.loadingMsg = 'Refreshing';
                            this.$forceUpdate();
                            var excessIDs = this.copyDeep(pickingSlip.consignmentIDs);
    
                            do {
                                var ids = excessIDs.slice(0, excessIDs.length > 20 ? 19 : excessIDs.length);
                                var results = this.copyDeep(await this.$BlitzIt.store.getAll('stock-consignments', { ids: ids.toString(), includeDetails: true }, true, null));
                                
                                results.forEach(consignment => {
                                    this.updateConsignment(consignment);
                                })
    
                                excessIDs = excessIDs.filter(x => !ids.some(y => y == x));
                            } while (this.isLengthyArray(excessIDs))
    
    
                        }
                        catch (err) {
                            this.msg = this.extractErrorDescription(err);
                        }
                        finally {
                            this.loadingMsg = null;
                        }
                    }
    
                    this.selectPickingSlip(this.pickingSlips.findIndex(y => y.slipType == pickingSlip.slipType && y.id == pickingSlip.id));
                }
            },
            async refreshStock() {
                try {
                    this.loadingMsg = 'Refreshing Stock';
                    this.$forceUpdate();
    
                    var stockItems = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.currentLocationID }, true, null);
    
                    stockItems.forEach(x => {
                        var existingEntry = this.allData.allStockEntries.find(entry => entry.productID == x.productID && entry.batchID == x.batchID);
                        if (existingEntry == null) {
                            //then add
                            this.allData.allStockEntries.push(Object.assign({}, x, { originalAvailable: x.available, quantityPicked: 0, quantity: 0 }));
                            this.msg = 'Added ' + x.product.productName;
                        }
                        else {
                            var dif = x.available - existingEntry.originalAvailable;
    
                            if (dif != 0) {
                                //is changed, so update
                                existingEntry.originalAvailable += dif;
                                existingEntry.available += dif;
    
                                this.msg = 'Updated ' + x.product.productName;
                            }
                        }
                    })
    
                    if (this.msg == null) {
                        this.msg = 'No Changes Found';
                    }
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            },
            async savePickingSlip(pickingSlip, updateTarget = false) {
                console.log('saving');
                if (pickingSlip != null) {
                    try {
                        //save any consignments that have entry items that are different
                        var changedConsignments = [];

                        if (updateTarget) {
                            if (pickingSlip.isTransfer === false) {
                                return;
                            }
                            else {
                                changedConsignments = [pickingSlip.filteredConsignments[0]];
                            }
                        }
                        else {
                            changedConsignments = pickingSlip.filteredConsignments.filter(x => x.isChanged);
                        }
    
                        if (this.isLengthyArray(changedConsignments)) {
                            var total = changedConsignments.length;
    
                            for (let ind = 0; ind < changedConsignments.length; ind++) {
                                const original = changedConsignments[ind];
                                var c = this.copyDeep(original);
                                
                                this.loadingMsg = `Saving ${ind + 1} of ${total} consignments`;
                                
                                var changedEntryItems = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID == c.id);
    
                                changedEntryItems.forEach(x => {
                                    if (x.difference != 0) {
                                        var stockEntry = this.allData.allStockEntries.find(y => y.productID == x.productID && y.batchID == x.batchID);
                                        if (stockEntry != null) {
                                            stockEntry.originalAvailable -= x.difference;
                                        }
                                        x.difference = 0;
                                    }

                                    x.isChanged = false;
                                })

                                c.consignmentEntryItems = this.copyDeep(changedEntryItems);

                                if (updateTarget) {
                                    c.consignmentOrderItems.forEach(cItem => {
                                        cItem.quantity = 0;
                                    });

                                    c.consignmentEntryItems.forEach(eItem => {
                                        var existingLine = c.consignmentOrderItems.find(z => z.productID == eItem.productID);
                                        if (existingLine == null) {
                                            existingLine = {
                                                id: null,
                                                quantity: 0,
                                                productID: eItem.productID,
                                                product: eItem.product
                                            };
                                            
                                            c.consignmentOrderItems.push(existingLine);

                                        }
                                        existingLine.quantity += eItem.quantity;
                                    });
                                }
                                else {
                                    c.consignmentOrderItems = null;
                                }

                                if (this.useFastProcessing) {
                                    c.isDispatchRequest = true;
                                }

                                var res = await this.$BlitzIt.store.patch('stock-consignments', c);

                                if (this.isLengthyArray(res.consignmentEntryItems)) {
                                    res.consignmentEntryItems.forEach(cItem => {
                                        var existing = this.allData.allConsignmentEntryItems.find(y => y.productID == cItem.productID && y.batchID == cItem.batchID);
                                        if (existing != null && existing.id == null) {
                                            existing.id == cItem.id;
                                        }
                                    })
                                }

                                original.consignmentEntryItems = this.copyDeep(changedEntryItems);

                                original.rowVersion = res.rowVersion;
                                original.isChanged = false;

                            }
                            
                            // //save mirror
                            // await this.saveMirror(pickingSlip);
                        }

                        if (this.useFastProcessing) {
                            this.loadingMsg = 'Processing';
                            var consignmentID = pickingSlip.filteredConsignments[0].id;
                            var releaseID = null;

                            while (releaseID == null) {
                                await this.twiddleThumbs(1500);
                                try {
                                    var releaseRes = await this.$BlitzIt.api.get('customer-consignments', '/GetMovementIDs/GetMovementIDs/' + consignmentID);
                                    console.log('res');
                                    console.log(releaseRes);
                                    if (releaseRes != null) {
                                        releaseID = releaseRes.data.data[0];
                                    }
                                }
                                catch {
                                    releaseID = null;
                                }
                            }

                            //navigate to stock consignment once found
                            var nav = { name: 'driver-hub', query: { id: releaseID, mode: 'pickup' } };
                            console.log('navving');
                            console.log(nav);
                            this.$router.push(nav);
                        }
                    }
                    catch (err) {
                        this.msg = this.extractErrorDescription(err);
                    }
                    finally {
                        this.loadingMsg = null;
                    }
                }
            },
            selectConsignment(consignmentID, pickingSlip) {
                if (pickingSlip == null) {
                    return;
                }
    
                this.allData.filteredStockEntries = [];
                var consignmentIDs = [];
    
                if (consignmentID == null) {
                    this.allData.consignmentID = null;
                    pickingSlip.consignmentID = null;
                    consignmentIDs = pickingSlip.consignmentIDs;
                }
                else {
                    this.allData.consignmentID = consignmentID;
                    pickingSlip.consignmentID = consignmentID;
                    consignmentIDs = [consignmentID];
                }
    
                if (!pickingSlip.isBulk && pickingSlip.consignmentID == null) {
                    //reset filtered items
                    pickingSlip.filteredConsignmentOrderItems = [];
                }
                else {
                    var cItems = this.allData.allConsignmentEntryItems.filter(x => consignmentIDs.some(id => id == x.consignmentID));
                    var oItems = this.allData.allConsignmentOrderItems.filter(x => consignmentIDs.some(id => id == x.consignmentID));
    
                    var products = [...new Set([...oItems.map(x => x.product), ...cItems.map(x => x.product)])];
    
                    var oList = [];
                    products.forEach(product => {
                        var orderItem = oList.find(x => x.productID == product.id);
                        if (orderItem == null) {
                            var relevantOItems = oItems.filter(y => y.productID == product.id);
                            var relevantCItems = cItems.filter(y => y.productID == product.id);

                            var qty = relevantOItems.sum(z => z.quantity);
                            var assigned = relevantCItems.sum(z => z.quantity);
                            var picked = relevantCItems.sum(z => z.quantityPicked);
                            var mirrorItem = null;

                            if (pickingSlip.mirror != null) {
                                mirrorItem = pickingSlip.mirror.pickingSlipItems.find(z => z.productID == product.id);
                            }
                            
                            oList.push({
                                productID: product.id,
                                product: product,
                                quantity: qty,
                                totalAssigned: assigned,
                                totalPicked: picked,
                                mirrorQuantity: mirrorItem != null ? mirrorItem.quantityNeeded : null,
                                mirrorAssigned: mirrorItem != null ? mirrorItem.quantityAssigned : null,
                                mirrorPicked: mirrorItem != null ? mirrorItem.quantityPicked : null
                                // mirrorDifQuantity: mirrorItem != null ? qty - mirrorItem.quantityNeeded : 0,
                                // mirrorDifAssigned: mirrorItem != null ? assigned - mirrorItem.quantityNeeded : 0,
                                // mirrorDifPicked: mirrorItem != null ? picked - mirrorItem.quantityPicked : 0
                            });
                        }
                    });

                    oList.sort(firstBy(x => x.product.sortNumber));

                    pickingSlip.filteredConsignmentOrderItems = oList.filter(x => x.totalAssigned != 0 || x.totalPicked != 0 || x.quantity != 0 || x.mirrorQuantity != null || x.mirrorAssigned != null || x.mirrorPicked != null);

                }
                
                //try select order item
                if (this.allData.currentOrderItem != null) {
                    var existingOItem = pickingSlip.filteredConsignmentOrderItems.find(y => y.productID == this.allData.currentOrderItem.productID);
                    this.selectOrderItem(existingOItem);
                }
                else {
                    this.allData.currentOrderItem = null;
                    this.selectOrderItem(null);
                }

                if (pickingSlip != null && pickingSlip.isPacking && consignmentID == null) {
                    this.stockDrawer = false;
                    this.slipDrawer = true;
                }
                else {
                    this.slipDrawer = false;
                    this.stockDrawer = false;
                }
            },
            selectOrderItem(orderItem, pickingSlip) {
                if (pickingSlip == null) {
                    this.stockDrawer = false;
                    return;
                }
    
                this.allData.currentOrderItem = orderItem;
                var consignmentID = this.allData.consignmentID;
    
                if (orderItem == null) {
                    this.allData.filteredStockEntries = [];
                    this.stockDrawer = false;
                }
                else {
                    this.allData.filteredStockEntries = this.allData.allStockEntries
                        .filter(x => x.productID == orderItem.productID)
                        .map(x => {
                            var localEntries = this.allData.allConsignmentEntryItems.filter(entry => entry.productID == x.productID && entry.batchID == x.batchID && (consignmentID == null && pickingSlip.consignmentIDs.some(id => id == entry.consignmentID) || entry.consignmentID == consignmentID));
    
                            x.quantityPicked = localEntries.sum(z => z.quantityPicked);
                            x.quantity = localEntries.sum(z => z.quantity);
    
                            x.originalPicked = x.quantityPicked;
                            x.originalQuantity = x.quantity;
    
                            return x;
                        });
    
                    this.allData.filteredStockEntries.sort(firstBy(x => x.batch != null ? x.batch.dom : null));
    
                    if (!this.isLengthyArray(this.allData.filteredStockEntries)) {
                        this.msg = 'No Stock';
                    }
                    else {
                        this.stockDrawer = true;
                    }
                }
            },
            selectPickingSlip(slipIndex) {
                if (slipIndex != null && slipIndex > -1) {
                    var slip = this.pickingSlips[slipIndex];
                    if (slip != null) {
                        slip.filteredConsignments = this.allData.allStockConsignments.filter(x => slip.consignmentIDs.some(id => id == x.id));
    
                        if (slip.isBulk) {
                            this.selectConsignment(null, slip);
                        }
                        else if (slip.consignmentIDs.length == 1) {
                            this.selectConsignment(slip.consignmentIDs[0], slip);
                        }
                        else if (slip.consignmentID != null) {
                            this.selectConsignment(slip.consignmentID, slip);
                        }
                    }
                    else {
                        this.allData.filteredStockEntries = [];
                        this.allData.consignmentID = null;
                        this.allData.currentOrderItem = null;
                    }
                }
                else {
                    this.allData.filteredStockEntries = [];
                    this.allData.consignmentID = null;
                    this.allData.currentOrderItem = null;
                }
            },
            //updates consignment order and entry items | returns the actual affected difference
            spreadAround(dif, stockEntryItem) {
                var prodID = stockEntryItem.productID;
                var batchID = stockEntryItem.batchID;
                var liveDif = dif;
    
                this.allData.allConsignmentOrderItems
                    .filter(x => x.productID == prodID && this.currentPickingSlip.consignmentIDs.some(y => y == x.consignmentID)) //find all relevant order items
                    .forEach(orderItem => {
                        //first find the needs of this order item
                        if (liveDif > 0) {
                            //look to add
                            var supplied = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID == orderItem.consignmentID && y.productID == prodID).sum(z => z.quantity);
                            var remainingNeeds = orderItem.quantity - supplied;
                            if (remainingNeeds > 0) {
                                var cItem = this.allData.allConsignmentEntryItems.find(x => x.consignmentID == orderItem.consignmentID && x.productID == orderItem.productID && x.batchID == batchID);
                                if (cItem == null) {
                                    //new entry item
                                    cItem = {
                                        id: null,
                                        isChanged: true,
                                        consignmentID: orderItem.consignmentID,
                                        productID: prodID,
                                        product: orderItem.product,
                                        quantity: 0,
                                        quantityPicked: 0,
                                        batch: stockEntryItem.batch,
                                        batchID: stockEntryItem.batchID,
                                        difference: 0,
                                    }
                                    this.allData.allConsignmentEntryItems.push(cItem);
                                }
                                
                                if (remainingNeeds >= liveDif) {
                                    orderItem.totalAssigned += liveDif;
                                    cItem.quantity += liveDif;
                                    cItem.difference += liveDif;
                                    liveDif = 0;
                                }
                                else {
                                    orderItem.totalAssigned += remainingNeeds;
                                    cItem.quantity += remainingNeeds;
                                    cItem.difference += remainingNeeds;
                                    liveDif -= remainingNeeds;
                                }

                                this.markAsChanged(cItem.consignmentID);
                            }
                            else {
                                console.log('other');
                            }
                        }
                        else if (liveDif < 0) {
                            //look to remove
                            var cItems = this.allData.allConsignmentEntryItems.filter(y => y.consignmentID == orderItem.consignmentID && y.productID == prodID)
                            cItems.forEach(x => {
                                if (liveDif < 0) {
                                    if (x.quantity > 0) {
                                        if (x.quantity >= (0 - liveDif)) {
                                            orderItem.totalAssigned += liveDif;
                                            x.quantity += liveDif;
                                            x.difference += liveDif;
                                            liveDif = 0;
                                        }
                                        else {
                                            orderItem.totalAssigned -= x.quantity;
                                            liveDif += x.quantity;
                                            x.difference -= x.quantity;
                                            x.quantity = 0;
                                        }

                                        x.isChanged = true;
                                    }
    
                                    this.markAsChanged(x.consignmentID);
                                }
                            })
                        }
                    });
    
                if (liveDif > 0) {
                    var consignmentID = this.currentPickingSlip.consignmentIDs.find(cID =>
                        this.allData.allConsignmentOrderItems.some(y => y.productID == stockEntryItem.productID && y.consignmentID == cID)
                        && this.allData.allConsignmentEntryItems.some(y => y.productID == stockEntryItem.productID && y.consignmentID == cID && y.batchID == stockEntryItem.batchID));

                    if (consignmentID == null) {
                        //nothing assigned of this stock item yet
                        consignmentID = this.currentPickingSlip.consignmentIDs.find(cID => this.allData.allConsignmentOrderItems.some(y => y.productID == stockEntryItem.productID && y.consignmentID == cID));
                    }

                    if (consignmentID != null) {
                        var oItem = this.allData.allConsignmentOrderItems.find(y => y.productID == stockEntryItem.productID && y.consignmentID == consignmentID);
                        var eItem = this.allData.allConsignmentEntryItems.find(y => y.productID == stockEntryItem.productID && y.consignmentID == consignmentID && y.batchID == stockEntryItem.batchID);
    
                        if (eItem == null) {
                            //add new entry item
                             eItem = {
                                id: null,
                                isChanged: true,
                                consignmentID: consignmentID,
                                productID: stockEntryItem.productID,
                                product: stockEntryItem.product,
                                quantity: 0,
                                quantityPicked: 0,
                                batch: stockEntryItem.batch,
                                batchID: stockEntryItem.batchID,
                                difference: 0,
                            }
                            
                            this.allData.allConsignmentEntryItems.push(eItem);
                        }

                        oItem.totalAssigned += liveDif;
                        eItem.quantity += liveDif;
                        eItem.isChanged = true;
                        
                        liveDif = 0;
                        this.markAsChanged(consignmentID);
                    }
                    else {
                        //then assign remaining to random consignment

                        console.log('no con found');
                    }
                }
            
                return (dif - liveDif);
            },
            updateConsignment(consignment) {
                var existing = this.allData.allStockConsignments.find(y => y.id == consignment.id);
    
                if (existing == null) {
                    this.addConsignment(consignment);
                }
                else {
                    existing.rowVersion = consignment.rowVersion;
                    existing.consignmentOrderItems = consignment.consignmentOrderItems;
    
                    this.allData.allConsignmentOrderItems = this.allData.allConsignmentOrderItems.filter(y => y.consignmentID != consignment.id);
    
                    consignment.consignmentOrderItems.forEach(oItem => {
                        this.allData.allConsignmentOrderItems.push(Object.assign({}, oItem, { totalAssigned: 0, totalPicked: 0, consignmentID: consignment.id } ));
                    })
    
                    existing.isSourceChanged = false;
                }
            }
        }
    }
</script>